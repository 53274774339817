import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/posts-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Thème du défi :`}</em>{` `}<a parentName="p" {...{
        "href": "https://fr.wikipedia.org/wiki/Narrateur_non_fiable"
      }}><em parentName="a">{`Narrateur non fiable`}</em></a></p>
    <p>{`A la suite de la Grande Guerre entre l’Empire de Quies et le Royaume de Cairnhorn, on avait décidé qu’une large zone démilitarisée serait instaurée entre les deux pays. Il serait strictement interdit d’y positionner des unités militaires et cette zone tampon serait administrée par une puissance neutre, le pays de Darousie. L’officier Gabert Bonchemin y travaillait désormais depuis plusieurs en tant intendant principal, il devait veiller ce que la zone reste absolument neutre et débarrassé de tout conflit. Il siégeait au Centre de Rencontre, un petit bâtiment utilisé comme point de rencontre neutre pour les deux puissances ou aucune arme n’était autorisée sauf celles des soldat de Darousie.`}</p>
    <p>{`L’officier Bonchemin avait passé une nuit affreuse. Un général de l’Empire de Quies, du nom de Carl Geso, avait purement et simplement disparu dans un vacarme de coups de feu. Il fallait alors enquêter sur place en urgence et inspecter le moindre indice dans les ténèbres de la nuit noire. Après avoir passé la nuit dehors dans le froid, il était revenu dans son bureau et se préparait à lire deux lettres qu’on lui avait remis en chemin. Chacune des lettres venait d’un côté de la frontière, l’une du commandement de l’Empire de Quies et l’autre du Maréchal du Royaume de Cairnhorn. Bien que tombant de sommeil, Gabert Bonchemin décacheta le sceau du Royaume et entama la lecture de la première lettre.`}</p>
    <p>{`« Très cher Officier Bonchemin, nous avons le regret d’apprendre la disparition du général Carl Geso des rangs impériaux. Je tiens à exprimer, au nom du Royaume de Cairnhorn, mes plus sincères condoléances à sa famille et ses proches.`}</p>
    <p>{`Nous nions solennellement toute implication du Royaume dans cette disparition et nous vous invitons à considérer tous les éléments qui aurait pu conduire à cet événement tragique.`}</p>
    <p>{`Nous tenons de source sure que Carl Geso était un membre éminent de l’Alliance Républicaine, une entité ennemie du pouvoir impérial en place. Il était devenu un adversaire de premier ordre du gouvernement et plusieurs membres de cette association ont déjà été assassinés au cours des dernières années. Celui du général Geso n’est malheureusement pas surprenant. Notre Royaume lui avait même proposé à plusieurs reprise de rejoindre notre pays, pour sa propre sécurité, une option qu’il a toujours refusé car toujours attaché à son pays natal où sa famille vit encore.`}</p>
    <p>{`De surcroît, la zone démilitarisée étant minée et les coups de feu ayant été perçu du côté impérial de la frontière (de source darousienne), il est impossible que le Royaume de Cairnhorn soit impliqué dans cette disparition.`}</p>
    <p>{`Nous attendons avec impatience les résultats de l’enquête afin de connaître les sanctions envers l’Empire de Quies que nous espérons fermes. Nous vous invitons aussi à apprécierez les quelques douceurs locales que nous vous avons envoyé.`}</p>
    <p>{`Maréchal Whitehull »`}</p>
    <p>{`Gabert posa la lettre à côté du panier de sucreries que le Royaume avait joint et, alors que ses paupières devenaient lourdes, enchaîna sans tarder par le papier venant de l’Empire de Quies.`}</p>
    <p>{`« Bonjour Gabert Bonchemin. Aujourd’hui l’Empire est en deuil. Nous avons été attaqué en plein coeur par notre plus vile ennemi. L’Empereur Pomeno a décrété un jour de deuil national ainsi que l’instauration immédiate de l’état d’urgence, ce crime ne peut rester impuni et nous vous assurons que l’Empire prendra les sanctions adéquate si vous ne vous en chargez pas.`}</p>
    <p>{`Carl Geso était une personne très active politiquement, un vrai symbole de paix pour le monde entier et l’Empereur l’a félicité de son engagement à plusieurs reprises et notamment lors de leur rencontre en Juin dernier. Son décès restera dans les mémoires comme la mort de la paix.`}</p>
    <p>{`Nous vous invitions naturellement à chercher les auteurs de cet acte abjecte du côté du Royaume de Cairnhorn et l’auteur de cet odieux assassinat devra recevoir une punition à la hauteur de l’horreur de ce crime.`}</p>
    <p>{`Que la paix demeure.`}</p>
    <p>{`Général Fredo »`}</p>
    <p>{`Gabert avait enfin fini la lecteur des deux lettres. Il attrapa les deux lettres dans sa main pour en faire une boule chiffonnée qu’il lança négligemment dans sa corbeille.`}</p>
    <p>{`Tout en prenant une barre chocolatée dans le panier venant du Royaume, il se remit à la tâche première de sa journée : comment allait-il se débarrasser du corps de Carl Geso qu’il avait assassiné pendant la nuit sur ordre de son commandant ?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      